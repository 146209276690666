
import { variables } from "@/plugins/variables"
import { Zone } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import NonAcces from "../components/NonAcces.vue"

export default Vue.extend({
  name: "Fosse",
  components: { NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      lesFosses: [] as Zone[],
      uneFosse: new Zone(),
      listeEtatFosse: [] as any[],
      listeTypesLaitiers: [] as string[],
      dialogueObservation: false,
      dialogueNouvelleFosse: false,
      dialogueSuppression: false,
      dictionnaire: [] as string[],
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "INITIALISER_FOSSE" }).then(response => {
        this.lesFosses = response.data.lesFosses.map((f: Zone) => {
          return new Zone(f)
        })
        this.listeTypesLaitiers = response.data.lesLaitiers
      })
    },
    couleurFosse(situation: string) {
      if (situation.split("/")[0] == "Remplissage") {
        return "background-color:#8BC34A;"
      } else if (situation == "Pleine") {
        return "background-color: red; background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);"
      } else if (situation == "Refroidissement eau") {
        return "background-color:#2196F3"
      } else if (situation == "Refroidissement air") {
        return "background-color:#B2EBF2"
      } else if (situation == "Vidange") {
        return "background-color:purple;color:white"
      }
    },
    remplissageLaitierBase(uneFosse: Zone) {
      if (uneFosse.getSituation() == "Remplissage") {
        uneFosse.setLaitier(this.listeTypesLaitiers[0])
      }
    },
    enregisterSituationFosse(uneFosse: Zone) {
      let situation = uneFosse.getSituation()
      if (uneFosse.getSituation() == "Remplissage") {
        situation += "/" + uneFosse.getLaitier()
      }
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "ENREGISTRER_SITUATION", sz_code: uneFosse.getCode(), sz_situation: situation })
    },
    ouvrirDialogueObservation(uneFosse: Zone) {
      this.uneFosse = uneFosse
      this.dialogueObservation = true
    },
    enregistrerObservation() {
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "ENREGISTRER_OBSERVATION", sz_code: this.uneFosse.getCode(), sz_observation: this.uneFosse.getObservation() }).then(() => {
        this.fermerDialogueObservation()
      })
    },
    fermerDialogueObservation() {
      this.uneFosse = new Zone()
      this.dialogueObservation = false
    },
    ouvrirDialogueNouvelleFosse() {
      this.uneFosse = new Zone()
      this.dialogueNouvelleFosse = true
    },
    enregistrerFosse() {
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "ENREGISTRER_FOSSE", uneFosse: this.uneFosse }).then(() => {
        this.lesFosses.push(this.uneFosse)
        this.fermerDialogueFosse()
      })
    },
    fermerDialogueFosse() {
      this.uneFosse = new Zone()
      this.dialogueNouvelleFosse = false
    },
    ouvrirDialogueSuppression(uneFosse: Zone) {
      this.uneFosse = uneFosse
      this.dialogueSuppression = true
    },
    suppressionFosse() {
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "SUPPRIMER_FOSSE", sz_code: this.uneFosse.getCode() }).then(() => {
        const index = this.lesFosses.findIndex((z: Zone) => z.getCode() == this.uneFosse.getCode())
        if (index != -1) {
          this.lesFosses.splice(index, 1)
        }
        this.dialogueSuppression = false
        this.uneFosse = new Zone()
      })
    },
    fermerdialogueSuppressionFosse() {
      this.dialogueSuppression = false
      this.uneFosse = new Zone()
    },
    enregistrerNomZone(uneFosse: Zone) {
      axios.post(this.$store.state.ajaxurl + "Fosse.php", { action: "ENREGISTRER_NOM_FOSSE", uneFosse: uneFosse }).then(() => {
        uneFosse.modifier = false
      })
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Obeservation", //0
            "Enregistrer", //1
            "Nouvelle fosse", //2
            "Nom fosse", //3
            "État fosse", //4
            "Enregistrer", //5
            "Suppression fosse", //6
            "Si vous supprimez cette fosse, vous ne pourrez plus enregistrer de nouveau transfert dans celle-ci.", //7
            "Voulez-vous vraiment supprimer cette fosse ?", //8
            "Annuler", //9
            "Supprimer", //10
            "Fosse" //11
          ]
          this.listeEtatFosse = [
            { value: "Refroidissement eau", text: "Refroidissement eau" },
            { value: "Refroidissement air", text: "Refroidissement air" },
            { value: "Pleine", text: "Pleine" },
            { value: "Vidange", text: "Vidange" },
            { value: "Vide", text: "Vide" },
            { value: "Remplissage", text: "Remplissage" }
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Bemerkung", //0
            "Registrieren", //1
            "Neue Grube", //2
            "Name Grube", //3
            "Zustand Grube", //4
            "Registrieren", //5
            "Löschen Grube", //6
            "Wenn Sie diese Grube löschen, können Sie keine neuen Transfers mehr in ihr speichern.", //7
            "Möchten Sie diese Grube wirklich beseitigen ?", //8
            "Abbrechen", //9
            "Löschen", //10
            "Grube" //11
          ]
          this.listeEtatFosse = [
            { value: "Refroidissement eau", text: "Wasserkühlung" },
            { value: "Refroidissement air", text: "Luftkühlung" },
            { value: "Pleine", text: "Voll" },
            { value: "Vidange", text: "Leerung" },
            { value: "Vide", text: "Leer" },
            { value: "Remplissage", text: "Füllen" }
          ]
          break
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    autFosse() {
      return variables.autorisation.includes("Fosse")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
